import React from "react";
import "../assets/scss/screen.scss";
import Header from "./Header";
import Footer from "./Footer";
import { graphql, useStaticQuery } from "gatsby";
import SearchPopup from "./SearchPopup";
import SearchPopupContext from "../util/searchPopupContext";
import BottomBanner from "../templates/bottomBanner";
import TelegramPopup from "./TelegramPopup"; // Import TelegramPopup

const Layout1 = ({ children }) => {
  const [popupVisible, setPopupVisible] = React.useState(false);
  const [telegramPopupOpen, setTelegramPopupOpen] = React.useState(false);

  const { site } = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          stickyNav
        }
      }
    }
  `);
  const { stickyNav } = site.siteMetadata;

  React.useEffect(() => {
    // Check localStorage to see if the popup was previously closed
    const isPopupClosed = localStorage.getItem('telegramPopupClosed');

    if (!isPopupClosed) {
      // Show the popup after 30 seconds if it hasn't been closed before
      const timer = setTimeout(() => {
        setTelegramPopupOpen(true);
      }, 30000); // Show popup after 30 seconds

      // Cleanup the timer
      return () => clearTimeout(timer);
    }
  }, []);

  const handlePopupClose = () => {
    // Set a flag in localStorage to prevent popup from opening again
    localStorage.setItem('telegramPopupClosed', 'true');
    setTelegramPopupOpen(false);
  };

  return (
    <div className="site-wrap" data-nav={stickyNav ? "sticky" : ""}>
      <SearchPopupContext.Provider value={{ popupVisible, setPopupVisible }}>
        <Header />
        {children}
        <BottomBanner />
        <Footer />
        <SearchPopup />
        
        {/* Add TelegramPopup component here */}
        <TelegramPopup
          isOpen={telegramPopupOpen}
          onClose={handlePopupClose} // Handle popup close
        />
      </SearchPopupContext.Provider>
    </div>
  );
};

export default Layout1;
